<template>
  <div class='teacher'>
    <list-template
        :loading="loading"
        :current-page='page'
        :search-config='searchConfig'
        :table-config='tableConfig'
        :table-data='tableData'
        :total='total'
        hasAdd
        @onAdd='$router.push("./add")'
        @onChangePage='changeCurrentPage'
        @onHandle='tableHandle'
        @onReset='search = null'
        @onSearch='onSearch'>
		</list-template>
  </div>
</template>

<script>
import searchBlock from '@/components/SearchBlock.vue';
import {mapState} from 'vuex';
import { get_2025pack } from '@/api'
import { setConfigOption } from '@/utils'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {
    searchBlock,
  },

  data() { // 这里存放数据
    return {
      loading:true,
      total: 0, // 总数
      search: null, // 搜索条件
      tableData: [], // 表格中的数据
      // 表格配置
      tableConfig: [{
        prop: 'pack_name',
        label: '商品套餐名称',
      }, {
        prop: 'pack_type_name',
        label: '套餐类型',
      }, {
        prop: 'pay_method',
        label: '是否支持线下支付',
        render: ({pay_method}) => pay_method == 1 ? '是' : '否',
      }, {
        prop: 'creator',
        label: '创建人',
      }, {
        prop: 'created_at',
        label: '创建时间',
      }, {
        width: 80,
        label: '操作',
        handle: true,
        render: () => ['编辑'],
      }],
      // 表格搜索配置
      searchConfig: [{
        prop: 'pack_type',
        tag: 'select',
        placeholder: '筛选套餐类型',
        options: []
        }, {
        prop: 'pack_name',
        placeholder: '搜索商品套餐名称',
      }],
    };
  },

  mounted() { // 生命周期 - 挂载完成（可以访问 DOM 元素）
    this.$store.commit('setPage', 1);
    this.getData();
    this.getConfig();
  },

  activated() { // 如果页面有 keep-alive 缓存功能,这个函数会触发  进入的时候触发
    this.getData();
  },

  computed: { // 计算属性 类似于 data 概念
    ...mapState(['page']),
  },

  methods: { // 方法合集
    changeCurrentPage(e) { // 分页被更改
      this.$store.commit('setPage', e);
      this.getData();
    },
    getConfig() {
      get_2025pack().then(res => {
        setConfigOption({ list: this.searchConfig , data: res, key: 'pack_type' })
      })
    },
    getData() { // 获取数据
      let { search, page } = this;
      this.loading = true;
      this.$_register.get('api/recruit/goods-manage/goods-pack-list', { params: { ...search, page } }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false);
    },

    onSearch(val) { // 搜索按钮被点击
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData();
    },

    tableHandle(row, text, index) { // 表格后面操作被点击
          this.$router.push('./edit?id=' + row.id);
    },
  },
};
</script>

<style scoped>
</style>
